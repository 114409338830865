
// General
$foreground-100: #6e7780;
$foreground-200: #59646e;
$foreground-300: #4c5661;
$foreground-400: #273038;
$foreground-500: #182027;
$foreground-600: #13191f;
$foreground-700: #0e1318;
$surface-darker: #f9fafc;
$surface-base: #fff;
$surface-over: #fff;
$orange-100: rgba(255,61,0,0.1);
$orange-200: rgba(255,61,0,0.18);
$orange-300: rgba(255,61,0,0.44);
$orange-400: #ff511a;
$orange-500: #f24d18;
$orange-600: #e54917;
$orange-700: #d94416;
$red-100: rgba(255,30,70,0.1);
$red-200: rgba(255,30,70,0.18);
$red-300: rgba(255,30,70,0.44);
$red-400: #ff1e46;
$red-500: #f21c43;
$red-600: #e51b3f;
$red-700: #d9193c;
$blue-100: rgba(0,148,255,0.1);
$blue-200: rgba(0,148,255,0.18);
$blue-300: rgba(0,148,255,0.44);
$blue-400: #0094ff;
$blue-500: #008df2;
$blue-600: #0085e5;
$blue-700: #007ed9;
$green-100: rgba(54,219,90,0.1);
$green-200: rgba(54,219,90,0.18);
$green-300: rgba(54,219,90,0.44);
$green-400: #36db5a;
$green-500: #35d959;
$green-600: #33d156;
$green-700: #30c451;
$purple-100: rgba(255,26,232,0.1);
$purple-200: rgba(255,26,232,0.18);
$purple-300: rgba(255,26,232,0.44);
$purple-400: #ff1ae8;
$purple-500: #f218dc;
$purple-600: #e517d1;
$purple-700: #d916c5;
$dark-overlay-100: hsla(0,0%,100%,0.76);
$dark-overlay-200: hsla(0,0%,100%,0.8);
$dark-overlay-300: hsla(0,0%,100%,0.84);
$dark-overlay-400: hsla(0,0%,100%,0.88);
$dark-overlay-500: hsla(0,0%,100%,0.92);
$dark-overlay-600: hsla(0,0%,100%,0.96);
$dark-overlay-700: #fff;
$light-overlay-100: rgba(73,86,116,0.02);
$light-overlay-200: rgba(73,86,116,0.06);
$light-overlay-300: rgba(73,86,116,0.1);
$light-overlay-400: rgba(73,86,116,0.14);
$light-overlay-500: rgba(73,86,116,0.18);
$light-overlay-600: rgba(73,86,116,0.22);
$light-overlay-700: rgba(73,86,116,0.26);
$divider-400: rgba(0,23,61,0.14);
$divider-300: rgba(0,23,61,0.18);
$divider-200: rgba(0,23,61,0.09);

// Colours
$red: #E41E13;
$red-dark: rgb(182, 0, 0);
$blue: #488AFF;
$blue-dark: #138fb6;
$green: #2ac845;
$teal: #48c0b7;
$purple: #854dff;
$purple-dark: rgb(6, 21, 81);
$orange: #f57c00;
$orange-light: #FEB47B;
$grey: #A6A6A6;
$grey-dark: #1d2026;
$grey-light: #c6c6c6;
$white: #ffffff;

// Theme
$color-primary: $purple;
$color-primary-light: lighten($color-primary, 5%);
$color-primary-dark: darken($color-primary, 10%);
$color-secondary: $purple;

// Page Layout
$page-background: white;
$page-secondary-background: #f9fafc;
$page-tertiary-background: rgba(125, 171, 255, 0.2);

// Typography
$font-color: #bcc2cc;
$font-color-light: #A6A6A6;
$anchor: $color-primary;
$anchor-hover: $anchor;


